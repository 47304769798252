<style lang="scss" scoped>
.poster-edit {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #f5f5f5;
  position: relative;
  font-size: 0.28rem;
  font-weight: 400;
  .top-box {
    width: 5.24rem;
    margin-bottom: 0.32rem;
    .top-btn {
      width: 2.08rem;
      height: 0.64rem;
      background: #1472ff;
      border-radius: 0.4rem;
      font-size: 0.28rem;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.64rem;
      float: right;
    }
  }
}
.poster {
  width: 100%;
  height: 100vh;
  // rgba 避免图片也有透明度
  // background: #000000;
  // opacity: 0.6;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  font-size: 0.24rem;
  color: #ffffff;
  line-height: 0.36rem;
  .img-box {
    z-index: 500;
    width: 4.54rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0.43rem;
    box-sizing: border-box;
    .result-box {
      width: 4.54rem;
      max-height: calc(100vh - 2rem);
      margin-bottom: 0.24rem;
      .result {
        width: 100%;
        height: 100%;
        border-radius: 0.24rem;
      }
    }
  }
  .close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.24rem;
    img {
      width: 0.32rem;
      height: 0.32rem;
    }
  }
}
.h-reduce {
  height: 8.08rem !important;
}
.review-box {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0.24rem;
  padding-bottom: 1.2rem;
  box-sizing: border-box;
}
.review {
  width: 5.24rem;
  position: relative;
  border-radius: 0.24rem;
  overflow: hidden;
  background-color: white; // 解决最终生成的海报图片，图片与下面的文字部分有间隙问题
  .review-img {
    width: 5.24rem;
    height: 9.32rem;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .review-text {
    width: 100%;
    height: 1.45rem;
    padding: 0.16rem 0.26rem 0 0.28rem;
    text-align: left;
    box-sizing: border-box;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    font-size: 0.22rem;
    background: #fff;
    color: #333333;
    font-weight: 400;
    li {
      margin-bottom: 0.06rem;
    }

    .qrcode-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .img-box {
        width: 0.84rem;
        height: 0.84rem;
        .qrcode-img {
          width: 100%;
          height: 100%;
        }
      }
      .qrcode-txt {
        font-size: 0.17rem !important;
        white-space: nowrap;
      }
    }
  }
}
.board {
  width: 100%;
  height: 2.24rem;
  background-color: #fff;
}
.img-list {
  display: flex;
  flex-wrap: nowrap;
  padding: 0.24rem 0.32rem 0.16rem;
  margin-right: 0.32rem; // 轮播图最后一张margin
  overflow-x: auto;
  .img-item {
    width: 1.02rem;
    height: 1.76rem;
    border-radius: 0.12rem;
    overflow: hidden;
    margin-right: 0.24rem;
    flex-shrink: 0; // 不压缩
    position: relative;
    .select {
      width: 0.32rem;
      height: 0.32rem;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  // 模板选中时样式
  .active {
    border: 0.04rem solid #105cfb;
    padding: 0.02rem;
  }
}
.bottom-tab {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 480px;
  height: 1.12rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  font-size: 0.2rem;
  font-weight: 400;
  color: #333333;

  .tab-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    img {
      width: 0.56rem;
      height: 0.56rem;
      margin: 0 auto;
    }
  }
  .click {
    color: #1472ff;
  }
}

.custom-sheet {
  height: 0.96rem;
  box-shadow: 0 -0.01rem 0 0 rgba(0, 0, 0, 0.08);
  font-size: 0.28rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.96rem;
}

.desc-box {
  display: flex;
  justify-content: space-between;
}
.primary {
  color: #105cfb;
}
.cancle {
  color: #999999;
}
.tip {
  font-size: 0.32rem;
  font-weight: 550;
  color: #323233;
}
.fw-4 {
  font-weight: 400;
}

// 只展示二维码时，设置位置靠右
.f-end {
  justify-content: flex-end !important;
}

// 海报无底部白边，img设置四周都有圆角
.r-all {
  border-radius: 0.24rem 0.24rem !important;
}
</style>

<style lang="scss">
.poster-edit {
  .van-action-sheet {
    .van-action-sheet__description {
      box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.2);
    }
    // 默认只展示两个选项
    .van-action-sheet__content {
      font-size: 0.28rem;
      font-weight: 400;
      .van-action-sheet__item {
        height: 0.96rem;
        box-shadow: 0 -0.01rem 0 0 rgba(0, 0, 0, 0.08);
        font-size: 0.28rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.4rem;
        &:active,
        &:focus {
          background: #f5f5f5;
          font-size: 0.32rem;
          font-weight: 550;
          color: #333333;
          line-height: 0.48rem;
        }
      }
    }
  }
}
// 解决只有点击"上传二维码"文案才能调文件上传的问题
.van-uploader {
  width: 100%;
  .van-uploader__input-wrapper {
    width: 100%;
    input {
      width: 100%;
    }
  }
}

// un_select(全局样式)  h5 ios 禁用长按选择图片
</style>

<template>
  <div class="poster-edit un_select">
    <div v-show="!showPoster">
      <div class="review-box">
        <div type="primary" @click="createPoster" class="top-box">
          <span class="top-btn">生成海报</span>
        </div>
        <div class="review" id="review">
          <!-- 移动端img下方出现1像素空隙 用div包裹img，img负责撑开div-->
          <div class="review-img" :class="isShowWhite ? '' : 'r-all'">
            <img :src="coverImgUrl" />
          </div>
          <div class="review-text" :class="isShowInfo ? '' : 'f-end'" v-if="isShowWhite">
            <ul v-if="isShowInfo">
              <li>姓名：{{ userInfo.user_name }}</li>
              <li>公司：{{ userInfo.company_simple }}</li>
              <li>联系方式：{{ userInfo.phone }}</li>
            </ul>
            <div class="qrcode-box" v-if="isShowQrcode">
              <div class="img-box">
                <img :src="localQrcodeUrl[0].content" class="qrcode-img" v-if="showQrcodeType == 'upload'" />
                <!-- 电子名片二维码 -->
                <canvas id="qr" class="qrcode-img" v-if="showQrcodeType == 'card'"></canvas>
                <!-- 个人企微码 -->
                <!-- <canvas ref="test" class="qrcode-img" width="600" height="600"></canvas> -->
                <img :src="qrCodeUrl" class="qrcode-img" v-if="showQrcodeType == 'person'" />
              </div>
              <div class="qrcode-txt">长按识别,了解更多</div>
              <!-- <div class="qrcode-txt">扫码咨询顾问</div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- 底部tab -->
      <div class="bottom-tab">
        <div class="tab-item" @click="clickTab1">
          <img :src="navIndex == 1 ? tabBarImgArr[0].selected : tabBarImgArr[0].normal" /><span :class="navIndex == 1 ? 'click' : ''">员工信息</span>
        </div>
        <div class="tab-item" @click="clickTab2">
          <img :src="navIndex == 2 ? tabBarImgArr[1].selected : tabBarImgArr[1].normal" /><span :class="navIndex == 2 ? 'click' : ''"
            >更换二维码</span
          >
        </div>
        <div class="tab-item" @click="clickTab3">
          <img :src="navIndex == 3 ? tabBarImgArr[2].selected : tabBarImgArr[2].normal" /><span :class="navIndex == 3 ? 'click' : ''">海报模板</span>
        </div>
      </div>

      <!-- 员工信息展示/不展示 -->
      <van-action-sheet :close-on-click-action="true" v-model="isShowInfoSheet" :actions="infoActions" @select="onSelectInfo" cancel-text="取消" />

      <!-- 选择二维码 -->
      <van-action-sheet v-model="isShowQrcodeSheet">
        <div v-for="(i, index) in qrcodeActions" :key="index" class="custom-sheet" @click="onSelectQrcode(i)">
          <van-uploader v-if="i.type_text === '上传二维码'" v-model="localQrcodeUrl" :after-read="afterRead" :preview-image="false">{{
            i.type_text
          }}</van-uploader>
          <div v-else>{{ i.type_text }}</div>
        </div>
      </van-action-sheet>

      <!-- 选择模板 -->
      <van-action-sheet v-model="isShowBoard">
        <div class="board">
          <div class="img-list" v-if="posterData.length">
            <div
              v-for="(item, index) in posterData"
              :key="item.id"
              @click="selectBoard(item.base_url, index)"
              class="img-item"
              :class="activeIndex == index ? 'active' : ''"
            >
              <img :src="item.base_url" alt="" />
              <img class="select" v-show="activeIndex == index" :src="require('../../assets/images/select.svg')" />
            </div>
          </div>
          <!-- 暂无海报时 -->
          <div class="img-list" v-else>
            <div class="img-item">
              <img :src="require('../../assets/images/nodata.svg')" alt="" />
            </div>
          </div>
        </div>
      </van-action-sheet>
    </div>

    <!-- 生成海报 -->
    <div class="poster" v-if="posterImg">
      <div class="img-box">
        <div class="close">
          <img :src="require('../../assets/images/close.svg')" @click="close" />
        </div>
        <div class="result-box" :class="isShowWhite ? '' : 'h-reduce'">
          <img :src="posterImg" class="result" />
        </div>
        <p>长按图片保存至相册</p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import html2canvas from "html2canvas";
import Qrious from "qrious";
import { getCookie } from "@/utils/cookies.js";

export default {
  name: "edit",
  data() {
    return {
      activeIndex: 0,
      navIndex: 0, // 底部导航索引
      posterData: [], // 海报列表数据
      qrCodeUrl: "", // 二维码链接
      coverImgUrl: "", // 海报预览图
      imgId: null, // 图片id
      posterImg: "", // 最终海报图
      showPoster: false, // 展示or隐藏导出的海报图
      type: 0, // 海报类型
      tabActive: 0, // 底部tab索引
      isShowInfoSheet: false, // 是否展示员工信息sheet
      isShowInfo: true, // 是否展示员工信息
      infoActions: [{ name: "展示" }, { name: "不展示" }],
      isShowQrcodeSheet: false, // 是否展示二维码sheet
      isShowQrcode: false, // 是否展示/存在二维码
      qrcodeActions: [],
      tabBarImgArr: [
        {
          normal: require("../../assets/images/nav_info.svg"),
          selected: require("../../assets/images/nav_info_active.svg"),
        },
        {
          normal: require("../../assets/images/nav_qrcode.svg"),
          selected: require("../../assets/images/nav_qrcode_active.svg"),
        },
        {
          normal: require("../../assets/images/nav_board.svg"),
          selected: require("../../assets/images/nav_board_active.svg"),
        },
      ],

      isShowBoard: false, // 是否展示海报模板
      localQrcodeUrl: [], // 本地上传的二维码图片
      userInfo: {},
      showQrcodeType: "", // 当前展示的二维码类型
    };
  },
  mounted() {
    let params = this.$route.query;
    this.coverImgUrl = params.base_url;
    this.type = params.bills_type;
    this.imgId = params.id;
    this.getList();
    this.getActionSheet();
  },
  // sheet关闭时，恢复底部导航初始颜色
  watch: {
    isShowQrcodeSheet(val) {
      if (!val) {
        this.navIndex = 0;
      }
    },
    isShowInfoSheet(val) {
      if (!val) {
        this.navIndex = 0;
      }
    },
    isShowBoard(val) {
      if (!val) {
        this.navIndex = 0;
      }
    },
  },
  computed: {
    // 用户信息和二维码都不展示时，去掉海报底部白色部分
    isShowWhite() {
      return this.isShowInfo || this.isShowQrcode;
    },
  },
  methods: {
    getList() {
      this.$ajax("bills_list", {
        ...(this.type ? { bills_type: this.type } : ""),
        page_index: 1,
        page_size: 9999,
      })
        .then(res => {
          var res = res.data;
          if (res.code === 0) {
            // 当前模板置顶
            let data = res.data.list || [];
            data.map((item, index) => {
              if (item.id === this.imgId) {
                data.unshift(data.splice(index, 1)[0]);
              }
            });
            this.posterData = data;
          } else {
            this.posterData = [];
            this.$toast.fail("海报获取失败");
          }
        })
        .catch(err => {
          this.posterData = [];
          this.$toast.fail("海报获取失败" + err);
        });
    },

    /**
     * 请求获客码列表
     *  入参--> 用户id：localStorage.setItem("userId", this.wework_user_id);
     *          应用id：应用id
     * */
    getActionSheet() {
      let user_id = localStorage.getItem("userId");
      let app_id = getCookie("with_app_id");
      this.$ajax("sheet_list", { user_id, app_id })
        .then(res => {
          var res = res.data;

          let _qrcodeActions = [
            { type_text: "上传二维码", id: 2 },
            { type_text: "不展示", id: 3 },
          ];
          if (res.code === 0) {
            // 组装二维码数据
            let data = res.data ? res.data.link_list : [];
            _qrcodeActions.unshift(...data);
            this.qrcodeActions = _qrcodeActions;
            // 组装员工信息
            this.userInfo = res.data ? res.data.user_info : {};
          } else {
            this.qrcodeActions = _qrcodeActions;
            this.$toast.fail("二维码获取失败");
          }
        })
        .catch(err => {
          this.qrcodeActions = _qrcodeActions;
          this.$toast.fail("二维码获取失败" + err);
        });
    },

    // 点击模板
    selectBoard(url, index) {
      this.activeIndex = index;
      this.coverImgUrl = url;
      this.isShowBoard = false;
    },

    setCreateLoading() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "生成中",
      });
    },
    // 生成海报
    createPoster() {
      this.setCreateLoading(); // loading
      const vm = this;
      const domObj = document.getElementById("review");
      html2canvas(domObj, {
        useCORS: true,
        allowTaint: true,
        width: domObj.clientWidth,
        height: domObj.clientHeight,
        scrollY: 0,
        scrollX: 0,
        backgroundColor: "#CC000000", // 设置画布颜色为蒙层颜色，解决视觉上图片白色缝隙问题
        // https://blog.csdn.net/weixin_33919941/article/details/88731607
        // 生成一个复制的虚拟组件
        // 海报预览时，需要显示圆角；保存图片到本地时，需要显示直角
        // 通过onclone实现，即html2canvas截取的是直角图片，预览图片设置border-radius
        onclone(doc) {
          let _domObj = doc.querySelector("#review");
          _domObj.style.overflow = "visible";
        },
      })
        .then(function (canvas) {
          let url = canvas.toDataURL("image/png");
          if (url) {
            vm.posterImg = url;
            vm.showPoster = true; // 隐藏选择模板页
            vm.$toast.clear();
          } else {
            vm.$toast.fail("海报生成失败");
          }
        })
        .catch(err => {
          console.log(err);
          vm.$toast.fail("海报生成失败" + err);
        });
    },

    // 关闭图片
    close() {
      this.posterImg = "";
      this.showPoster = false;
    },

    // 选择是否展示员工信息
    onSelectInfo(action) {
      if (action.name === "展示") {
        this.isShowInfo = true;
      } else {
        this.isShowInfo = false;
      }
    },
    // 选择二维码 个人企微码后端返回base64-解决html2canvas绘制跨域问题
    // 电子名片码需要转化为二维码
    onSelectQrcode(action) {
      if (action.type_text === "不展示") {
        this.isShowQrcode = false;
        this.showQrcodeType = "";
      } else if (action.type_text === "个人企微码") {
        this.isShowQrcode = true;
        this.qrCodeUrl = action.link;
        this.showQrcodeType = "person";

        // this.$nextTick(() => {
        //   var canvas = this.$refs.test;
        //   var context = canvas.getContext("2d");
        //   var img = document.createElement("img");
        //   img.crossOrigin = "use-credentials";
        //   img.src = "https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc2968064a8236f3e3";
        //   img.onload = function () {
        //     context.drawImage(img, 0, 0, 400, 400);
        //   };
        // });
      } else if (action.type_text === "电子名片码") {
        this.isShowQrcode = true;
        this.showQrcodeType = "card";

        this.$nextTick(() => {
          new Qrious({
            element: document.getElementById("qr"),
            value: action.link,
          });
        });
      }

      this.isShowQrcodeSheet = false;
    },

    // 文件上传完毕后会触发  localQrcodeUrl-[]  file-{}
    afterRead(file) {
      this.localQrcodeUrl = [file];
      this.isShowQrcode = true;
      this.showQrcodeType = "upload";
    },

    clickTab1() {
      this.isShowInfoSheet = true;
      this.navIndex = 1;
    },

    clickTab2() {
      this.isShowQrcodeSheet = true;
      this.navIndex = 2;
    },

    clickTab3() {
      this.navIndex = 3;
      if (this.posterData.length) {
        this.isShowBoard = true;
      }
    },
  },
};
</script>
